import React, { Component } from 'react'; //different
//import './style.css';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import NavMenu from './navmenu';
import Systems from "./SystemFunction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

var settings = {
    dots: true,
    infinite: true,
    slidesToShow: (window.innerWidth <= 760) ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear"
};
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tblbanner: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            playerid: null,
            user_id: null,
            point: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }

    }

    async componentDidMount() {
        var token = localStorage.getItem('auth_token');
        const search = window.location.href;
        const params = new URLSearchParams(search);
       await instance.get("/api/v1/tabbannerlist", {
            params: {
                System: Systems,
            }
          })
            .then((res) => {
                this.setState({
                    tblbanner: res.data.message
                });
            });
          this.setState({
            isActive: false
          });

    }

    render() {
        const { tblbanner } = this.state
        return (
            <div>
                <div
                    className="flicking-viewport"
                    style={{
                        userSelect: "none",
                        WebkitUserDrag: "none",
                        touchAction: "pan-y"
                    }}
                >
                    <Slider {...settings}>
                        {tblbanner.map((listitem, i) => (
                            <div
                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                data-slick-index={-i}
                                aria-hidden="true"
                                tabIndex={-i}

                            >
                                <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-9"
                                            src={listitem.path}
                                            alt="First slide"
                                            width={1200}
                                            height={590}
                                            style={{ borderRadius: "0.5rem !important" }}
                                        />
                                    </picture>
                                </div>
                            </div>
                        ))}

                    </Slider >

                </div >
            </div >

        );
    }
}
export default App;
